import React from 'react';
import '../../App.css';

export default function Quote() {
  return (
    <div className='quote'>
      <h2>Submit a Quote</h2>
      <form className='quoteForm' action="https://formsubmit.co/enquiry@ozenggsales.com" method="post" enctype="multipart/form-data">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="Name" placeholder='Enter name' />

        <label htmlFor="country">Country:</label>
        <input type="text" id="country" name="Country" placeholder='Enter country' />

        <label htmlFor="phone">Phone Number:</label>
        <input type="text" id="phone" name="Phone Number" placeholder='Enter phone number' />

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" placeholder='Enter email' />

        <label htmlFor="description">Description:</label>
        <input type="text" id="description" name="Description" placeholder='Enter description' />

        <label htmlFor="file">File Upload:</label>
        <input type="file" id="file" name="attachment" accept="image/png, image/jpeg" />

        <input type="hidden" name="_subject" value="New Quote Requested!" />
        <input type="hidden" name="_autoresponse" value="Quote Requested to Overseas Engineering Sales" />
        <button type="submit" className='quoteSubmit'>Submit</button>
      </form>
    </div>
  );
}
