import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import castingImage from '../images/casting.jpg';
import forgingImage from '../images/forging.jpg';
import fabricationImage from '../images/fabrication.jpg';
import machiningImage from '../images/machining.jpg';
import rapidImage from '../images/rapid.jpg';
import surfaceImage from '../images/surface.jpg';
import pipesImage from '../images/pipes.jpeg';
import tubesImage from '../images/tubes.jpeg';
import Cast1 from '../images/Cast and Machined 3.jpg';
import Cast2 from '../images/Cast and Machined 2.jpg';
import Cast3 from '../images/Cast and Machined.jpg';
import Cast4 from '../images/Cast and Machined1.jpg';
import forgedn from '../images/Gears Forged and machined.jpg';
import forged from '../images/Forged Machined.jpg';
import forged1 from '../images/Forged Machined 1.jpg';
import forged2 from '../images/Forged Machined 2.jpg';

function Cards() {
  return (
    <div className='cards'>
      <h1>Our Services Worldwide!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={castingImage}
              text='Castings (Ferrous and Non-ferrous) namely Gravity Die Casting, Low Pressure die casting and high pressure die castings'
              label='Casting'
              path='/'
            />
            <CardItem
              src={forgingImage}
              text='Forgings namely open die, impression, cold and seamless rolled ring'
              label='Forging'
              path='/'
            />
            <CardItem
              src={surfaceImage}
              text='Surface finishing operations like Electroplating, painting, brushing, powder coating, buffing as per your standards'
              label='Surface Finishing'
              path='/'
            />
            <CardItem
              src={forged}
              text='Forged & Machined'
              label='Forging'
              path='/'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={fabricationImage}
              text='Fabrication and sheet metal components.'
              label='Fabrication'
              path='/'
            />
            <CardItem
              src={machiningImage}
              text='Machining processes on above casting and forgings as per your drawings and standards.'
              label='Machining'
              path='/'
            />
            <CardItem
              src={rapidImage}
              text='Rapid prototyping SLA, SLS, DMLS, Vacuum casting'
              label='Rapid Prototyping'
              path='/'
            />
            <CardItem
              src={forged1}
              text='Forged & Machined'
              label='Forging'
              path='/'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={pipesImage}
              label='Pipes and tubes'
              text='Plumbing Pipes'
              path='/'
            />
            <CardItem
              src={tubesImage}
              label='Pipes and tubes'
              text='Tubes'
              path='/'
            />
                        <CardItem
              src={forged2}
              text='Forged & Machined'
              label='Forging'
              path='/'
            />
                        <CardItem
              src={forgedn}
              text='Gears Forged & Machined'
              label='Forging'
              path='/'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={Cast1}
              label='Machining'
              text='Cast & Machined'
              path='/'
            />
            <CardItem
              src={Cast2}
              label='Machining'
              text='Cast & Machined'
              path='/'
            />
                        <CardItem
              src={Cast3}
              label='Machining'
              text='Cast & Machined'
              path='/'
            />
                        <CardItem
              src={Cast4}
              label='Machining'
              text='Cast & Machined'
              path='/'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
