import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import logo from '../images/logo.jpeg';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src={logo} alt='Logo' id="logo"/>
            </Link>
          </div>
          <small className='website-rights'>© 2024 Overseas Engineering Sales</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
