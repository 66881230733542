import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Quote from './components/pages/Quote'
import Contact from './components/pages/ContactUs'
import Footer from './components/Footer';

import './App.css';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about' component={About} />
          <Route path='/quote' component={Quote} />
          <Route path='/contact' component={Contact} />
        </Switch>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
