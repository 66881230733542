import React from 'react';
import '../../App.css';

export default function Contact() {
  return (
    <div className='contact'>
      <h1>Contact Us</h1>
      <div className="contact-section">
        <h4>E-mail:</h4>
        <ul>
          <li><a href="mailto:enquiry@ozenggsales.com">enquiry@ozenggsales.com</a></li>
          <li><a href="mailto:inder@ozenggsales.com">inder@ozenggsales.com</a></li>
          <li><a href="mailto:reet@ozenggsales.com">reet@ozenggsales.com</a></li>
        </ul>
      </div>
      <div className="contact-section">
        <h4>Phone:</h4>
        <ul>
          <li>+91-9501840169</li>
        </ul>
      </div>
      <div className="contact-section">
        <h4>Address:</h4>
        <ul>
          <li>SCO-6, Wadhawa Nagar, Zirakpur, SAS Nagar, Punjab-140604, INDIA.</li>
        </ul>
      </div>
    </div>
  );
}
