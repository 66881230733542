import React from 'react';
import '../../App.css';

export default function About() {
  return (
    <>
        <div className='about'>
        <h1>
          Your supply chain companions are here!
        </h1>
        <p> 
        At Overseas Engineering Sales (OES), we expertize in providing end to end solutions for all your automotive and industrial needs.<br/> We have an edge in field of castings, forging, machining, sheet metal, fabrication. Also, if you have any prototyping requirements, yes we are there for you. We help the companies to find products of certain desired quality and definitely at competitive cost. We ensure that the supplier can manufacture and supply the commodities in timely manner.
        We at Overseas Engineering Sales (OES) can help the inspection and audit of vendor to ensure quality processing. We seek quotes from multiple companies so that we find the best possible vendors to serve your company best.        </p>
        <p> 
        The major challenges that importers face is finding the right product suppliers in India. As an experienced engineering sourcing agent, Overseas Engineering Sales (OES) will help you to locate and procure the exact products that you are looking for, in India.        
        Overseas Engineering Sales (OES) not only helps you to find right products and right manufacturers but also thrive for the best prices and MOQ with the manufacturers and suppliers on your behalf.
        </p>
        </div>
    </>
  );
}
